import { bindable, inject }          from 'aurelia-framework';
import { PLATFORM }                  from 'aurelia-pal';
import { BaseFormViewModel }         from 'base-form-view-model';
import { FormSchema }                from 'modules/core/account-settings/form-schema';
import { AccountSettingsRepository } from 'modules/core/account-settings/services/repository';
import { AppContainer }              from 'resources/services/app-container';

@inject(AppContainer, FormSchema, AccountSettingsRepository)
export class EditAccountSettings extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.account-settings';
    @bindable formId      = 'core-account-settings-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, formSchema, repository) {
        super(appContainer);

        this.formSchema = formSchema;
        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/core/account-settings/index.html');
    }

    /**
     * Handles activate event
     *
     * @returns {Promise.<*>|Promise}
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.infoSchema     = this.formSchema.infoSchema(this);
            this.passwordSchema = this.formSchema.passwordSchema(this);
        });
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return this.repository.find().then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

    /**
     * Submits view form
     *
     * @return {Promise.<*>|Promise}
     */
    submit() {
        this.alert = null;

        return this.repository.update({
            current_password:      this.model.current_password,
            password:              this.model.password,
            password_confirmation: this.model.password_confirmation,
        }).then(response => this.afterSubmitCallback(response));
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.resetForm();
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

}
