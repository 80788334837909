import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class LotInterventionSelectionRepository extends BaseRepository {

    /**
     * selects a record
     *
     * @param data
     *
     * @returns {*}
     */
    select(data) {
        return this.httpClient.post(`auth/select-lot-intervention`, data);
    }
}
