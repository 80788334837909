import { bindable, containerless, inject } from 'aurelia-framework';
import { RelativeTime }                    from 'aurelia-i18n';
import { AppContainer }                    from 'resources/services/app-container';
import { LaravelEchoService }              from 'resources/services/laravel-echo-service';
import { MessagesRepository }              from 'modules/personal-area/messages/services/repository';
import { UsersRepository }                 from 'modules/administration/users/services/repository';

@containerless()
@inject(AppContainer, RelativeTime, MessagesRepository, UsersRepository, LaravelEchoService)
export class MessagesCard {
    @bindable headerTitle = 'dashboard.title.messages';

    criteria = {};
    messages = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param relativeTime
     * @param repository
     * @param usersRepository
     * @param laravelEchoService
     */
    constructor(appContainer, relativeTime, repository, usersRepository, laravelEchoService) {
        this.appContainer       = appContainer;
        this.relativeTime       = relativeTime;
        this.repository         = repository;
        this.usersRepository    = usersRepository;
        this.laravelEchoService = laravelEchoService;
    }

    /**
     * Handles activate event
     */
    bind() {
        this.fetchData();
        this.subscribeMessagesChannel();
    }

    /**
     * Fetches data from server
     *
     * @returns {*}
     */
    fetchData() {
        return this.usersRepository.unreadMessages().then(messages => {
            this.messages.splice(0, this.messages.length, ...messages);
        });
    }

    /**
     * Subscribes messages channel
     */
    subscribeMessagesChannel() {
        if (this.laravelEchoService.instantiated) {
            window.Echo
                .private('Ascendi.RumoQ.Models.User.' + this.appContainer.authenticatedUser.user.id)
                .listen('UserMessagesUpdated', (event) => this.fetchData());
        }
    }

    goToMessage(row) {
        let route = this.appContainer.router.generate('personal-area.messages.details', {id: row.id});

        // marks the message has read
        this.repository.read(row.id)
            .then(() => this.appContainer.router.navigate(route));
    }

    /**
     * Gets relative time from a given date
     *
     * @param date
     *
     * @returns {*|any}
     */
    getRelativeTime(date) {
        let time = new Date(date);

        return this.relativeTime.getRelativeTime(time);
    }

}
