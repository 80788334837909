import { inject }     from 'aurelia-framework';
import { Downloader } from 'resources/services/downloader';

@inject(Downloader)
export class FileCustomListingCell {
    /**
     * Constructor.
     *
     * @param downloader
     */
    constructor(downloader) {
        this.downloader = downloader;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column          = model.column;
        this.row             = model.row;
        this.filesRepository = model.column.filesRepository;
        this.alwaysVisible   = model.column.alwaysVisible;
        this.icon            = model.column.icon;
        this.action = (row) => model.column.action(row);
    }

    /**
     * Downloads the file
     */
    downloadFile(model) {
        // remove the file extension from the file name
        const fileName = this.row ? this.row.file.display_name.split('.').slice(0, -1).join('.') : model.existing_file.display_name.split('.').slice(0, -1).join('.');

        this.filesRepository
            .download(this.row ? this.row.file_id : model.file_id)
            .then((blob) => this.downloader.download(blob, fileName, this.row ? this.row.file.extension : model.existing_file.extension));
    }
}
