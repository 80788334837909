import { BaseModel } from 'modules/core/models/base-model';

export class LotInterventionSelection extends BaseModel {
    id                   = null;
    concession_id        = null;
    highway_id           = null;
    intervention_type_id = null;
    lot_intervention_id  = null;
    team_id              = null;
    show_team_name       = false;
}
