import { inject }                             from 'aurelia-framework';
import { PLATFORM }                           from 'aurelia-pal';
import { activationStrategy }                 from 'aurelia-router';
import { BaseFormViewModel }                  from 'base-form-view-model';
import { LotInterventionSelectionFormSchema } from 'modules/concessions/lot-intervention-selection/form-schema';
import { LotInterventionSelectionRepository } from 'modules/concessions/lot-intervention-selection/services/repository';
import { HighwaysRepository }                 from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository }        from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { ConcessionsRepository }              from 'modules/management/specifications/concessions/services/repository';
import { AppContainer }                       from 'resources/services/app-container';

@inject(AppContainer, LotInterventionSelectionRepository, LotInterventionSelectionFormSchema, ConcessionsRepository, HighwaysRepository, InterventionTypesRepository)
export class CreateConcession extends BaseFormViewModel {

    headerTitle = 'form.title.select-lot-intervention';
    formId      = 'concessions.select-lot-intervention';
    backRoute   = null;

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param concessionsRepository
     * @param highwaysRepository
     * @param interventionTypesRepository
     */
    constructor(appContainer, repository, formSchema, concessionsRepository, highwaysRepository, interventionTypesRepository) {
        super(appContainer);

        this.repository                  = repository;
        this.formSchema                  = formSchema;
        this.concessionsRepository       = concessionsRepository;
        this.highwaysRepository          = highwaysRepository;
        this.interventionTypesRepository = interventionTypesRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/button-form.html');
    }

    /**
     * Determines activation strategy
     *
     * @return {'replace'}
     */
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate(params);

        this.backRoute = params.backRoute;
        this.params    = params;

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchFromAuthenticatedUser();

        this.schema       = await this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
    }

    /**
     * Fetches data from authenticated user
     */
    async fetchFromAuthenticatedUser() {
        const lotInterventionIds = this.appContainer.authenticatedUser.getSelectedLotInterventionIds();

        if (lotInterventionIds) {
            this.initialModel.assign(lotInterventionIds);
            this.model.assign(lotInterventionIds);
        }
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.select(this.model)
            .then((response) => {
                if (!response.token) {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);

                    return;
                }

                this.initialModel.assign(this.model);

                this.appContainer.authService.setToken(response.token);

                if (this.backRoute) {
                    this.appContainer.router.navigateToRoute(this.backRoute, ...(this.params || {}));
                } else {
                    this.appContainer.router.navigateToRoute('dashboard');
                }

                this.appContainer.eventAggregator.publish('lot-intervention-selected');
            })
            .catch((error) => console.error(error));
    }
}
